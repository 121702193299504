<template>
  <CHeader with-subheader>

    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />

    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img class="d-none d-sm-block img-drop-shadow" src="/img/menute.be/logo.png" width="150" alt="Menute.be" />
      <img class="d-block d-sm-none img-drop-shadow" src="/img/menute.be/logo.png" width="120" alt="Menute.be" />
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">

      <CHeaderNavItem class="px-2">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem>
        <CountryDD />
      </CHeaderNavItem>

    </CHeaderNav>

    <CHeaderNav>
      <!-- Print -->
      <CHeaderNavItem class="c-d-legacy-none mr-0 mr-sm-2">
        <a class="c-header-nav-btn" href="#" onclick="javascript:window.print();">
          <CIcon name="cil-print" size="lg" />
        </a>
      </CHeaderNavItem>

      <!-- Toggle light/dark -->
      <!--
      <CHeaderNavItem class="c-d-legacy-none mr-0 mr-sm-2">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun" size="lg" />
          <CIcon v-else name="cil-moon" size="lg" />
        </button>
      </CHeaderNavItem>
      -->

      <template v-if="!restrictedUser">
        <!-- Tasks -->
        <TheHeaderDropdownTasks />

        <!-- Email notifications -->
        <TheHeaderDropdownMssgs />
      </template>

      <!-- Logout -->
      <CHeaderNavItem class="d-none d-md-block">
        <button
          @click="logout" class="c-header-nav-btn"
        >
          <CIcon name="cil-lock-locked" size="lg" />
        </button>
      </CHeaderNavItem>

      <TheHeaderDropdownAccnt class="mr-0 mr-sm-3" />

      <!--
      <CHeaderNavItem class="px-3">
        <button
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem>
      -->
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import { mapActions } from "vuex";
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import CountryDD from "../components/CountryDD.vue";

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownMssgs,
    TheHeaderDropdownTasks,
    CountryDD,
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),

    async logout() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Are you sure you want to logout?`,
        button: { no: "No", yes: "Yes" },
        callback: async (confirm) => {
          if (confirm) {
            await this.sendLogoutRequest();
            this.$router.push({ name: "Login" });
          }
        }
      });
    },
  },
}
</script>

<style scoped>
.img-drop-shadow {
  filter: drop-shadow(1px 1px 1px #9b0a0a);
}
</style>
