<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <CImg
            :src="userData?.user.image_icon || userData?.user.gravatar"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </div>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center" color="light">
      <div v-if="userData">
        <strong>{{ userData.user.first_name }}</strong><br>
        <strong>{{ `${userData.user.email} (#${userData.user.id})` }}</strong>
      </div>
    </CDropdownHeader>

    <!-- <CDropdownItem>
      <CIcon name="cil-bell"/> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>

    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>

    <CDropdownItem> <CIcon name="cil-user" /> Profile </CDropdownItem>
    <CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem> -->

    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'TheHeaderDropdownAccnt',

  computed: {
    ...mapGetters("auth", ["userData"]),
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),

    async logout() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Are you sure you want to logout?`,
        button: { no: "No", yes: "Yes" },
        callback: async (confirm) => {
          if (confirm) {
            await this.sendLogoutRequest();
            this.$router.push({ name: "Login" });
          }
        }
      });
    },
  }

}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
