<template>
  <CFooter :fixed="false">
    <div>
      Copyright &copy; {{new Date().getFullYear()}} <a href="https://menute.be" target="_blank">Menute.be</a>
    </div>
    <div class="mfs-auto">
      <span>Admin Panel v1.0</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
