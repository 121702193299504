<template>
  <CDropdown color="link" :caret="true" class="header-country-dd">
    <template #toggler-content>
      <CIcon class="mr-1" :content="$options.flagSet[country.flag]" size="lg" />
      <span>{{ country.label }}</span>
    </template>

    <CDropdownItem v-for="(c, index) in authCountries" :key="index" @click="() => (country = c.value)">
      {{ c.label }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { flagSet } from '@coreui/icons'

export default {
  name: "CountryDD",
  flagSet,
  computed: {
    country: {
      get() {
        const foundCountry = this.authCountries.find((c) => c.value == this.$store.state.countryId);
        return foundCountry || this.authCountries[0];
      },
      set(newValue) {
        this.$store.commit("setCountry", newValue);
      },
    },
  },
};
</script>

<style>
.header-country-dd {
  border: 1px solid;
  border-radius: 0.25rem;
  border-color: #d8dbe0;
  background-color: #c6e2ffAA;
}

.header-country-dd>.btn {
  color: rgba(0, 0, 21, 0.5);
  text-decoration: none;
}
</style>
