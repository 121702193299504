<template>
  <CDropdown
    :caret="false"
    placement="bottom-end"
    in-nav
    class="c-header-nav-item mx-0 mx-sm-2"
    add-menu-classes="pt-0"
    :show.sync="show"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-list-rich" size="lg" />
        <CBadge v-if="crm.hasPendingTask" shape="pill" color="warning" class="badge-point">&nbsp;</CBadge>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center bg-light">
      <CSpinner size="sm" v-if="loading" />
      <strong v-else>
        {{ tasks.length > 0 ? `You have ${tasks.length} pending tasks` : 'You have no pending task!' }}
      </strong>
    </CDropdownHeader>

    <template v-for="task in tasks">
      <CDropdownItem class="d-block" :to="{ name: 'Message', params: { id: task.task_id, folder: 'inbox' } }">
        <div class="small mb-1">
          {{ task.subject }} <span class="float-right"></span>
        </div>
        <CProgress class="progress-xs" :color="task.labelObj.color" :value="task.labelObj.progress"/>
      </CDropdownItem>
    </template>

    <CDropdownItem :to="{ name: 'Dashboard' }" class="border-top">
      <strong>View all tasks</strong>
    </CDropdownItem>

  </CDropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TheHeaderDropdownTasks',
  data () {
    return {
      tasks: [],
      show: false,
      loading: false,
    }
  },

  computed: {
    ...mapState(['crm']),
  },

  watch: {
    show: async function (val) {
      if (val) {
        this.tasks = JSON.parse(localStorage.getItem('my-pending-tasks')) || [];
        await Promise.all([this.getTasks()]);
      }
    },
  },

  methods: {
    async getTasks() {
      var params = { status: 'pending' };

      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_TASKS.GET_MY, { params })
        .then((response) => {
          this.tasks = response.data
            .filter((el) => el.is_my_task)
            .map((el) => {
              return {
                // ...el,
                task_id: el.task_id,
                subject: el.subject,
                labelObj: this.getLabel(el.label),
              };
            });
        })
        .finally(() => {
          this.loading = false;
          localStorage.setItem('my-pending-tasks', JSON.stringify(this.tasks));
        });
    },

    getLabel(value) {
      return value ? this.$globalVar.emailLabels.find((el) => el.value === value) : null;
    },
  },
}
</script>

<style>
.dd-tasks > .dropdown-menu.show > a {
  min-width: 500px;
  width: 500px;
}

.dd-tasks > .dropdown-menu.show > a > .message {
  width: 100%;
}
</style>
