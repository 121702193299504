<template>
  <CDropdown
    :caret="false"
    placement="bottom-end"
    in-nav
    class="dd-emails c-header-nav-item mx-0 mx-sm-2"
    add-menu-classes="pt-0"
    :show.sync="show"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon :name="`cil-envelope-${crm.hasUnreadMail ? 'closed' : 'open'}`" size="lg" />
        <CBadge v-if="crm.hasUnreadMail" shape="pill" color="danger" class="badge-point">&nbsp;</CBadge>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center bg-light">
      <CSpinner size="sm" v-if="loading" />
      <strong v-else>
        {{ emails.length > 0 ? `You have ${emails.length} messages` : 'No new message!' }}
      </strong>
    </CDropdownHeader>

    <template v-for="email in emails">
      <CDropdownItem :to="{ name: 'Message', params: { id: email.message_id, folder: 'inbox' } }">
        <div class="message">
          <div class="pt-3 mr-3 float-left">
            <div class="c-avatar">
              <CImg
                :src="email.message.sender?.image_icon || email.message.sender?.gravatar"
                class="c-avatar-img"
              />
            </div>
          </div>
          <div>
            <CBadge v-if="email.message.is_outgoing" color="light"><span class="text-info"><CIcon name="cil-send" /> Outgoing Email</span></CBadge>
            <small class="text-muted">{{ email.message.is_outgoing ? `${email.message.reply_to?.toString()}` : email.message.sender?.first_name }}</small>
            <small class="text-muted float-right mt-1">{{ moment(email.message.created_at).fromNow() }}</small>
          </div>
          <div class="text-truncate font-weight-bold">
            <CIcon v-if="email.isMyTask" name="cil-bolt" class="text-danger" />
            {{ email.message.subject }}
          </div>
          <div class="small text-muted text-truncate">{{ email.message.plain_reply }}</div>
        </div>
      </CDropdownItem>
    </template>

    <CDropdownItem
      :to="{ name: 'Email' }"
      class="border-top"
    >
      <strong>View all messages</strong>
    </CDropdownItem>

  </CDropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TheHeaderDropdownMssgs',
  data () {
    return {
      emails: [],
      show: false,
      loading: false,
      email: {},
    }
  },

  computed: {
    ...mapState(['crm']),
  },

  watch: {
    show: async function (val) {
      if (val) {
        await Promise.all([this.getMessages()]);
      }
    },
  },

  async mounted() {
  },

  methods: {
    async getMessages() {
      var params = { folder: 'inbox', unread: true };

      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_EMAILS.GET_ALL, { params })
        .then((response) => {
          this.emails = response.data.map((email) => {
            return {
              message: this.getLastReply(email),
              message_id: email.message_id.match(/^([^@]*)@/)[1],
              isMyTask: email.is_my_task,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getLastReply(email) {
      const length = email.childs.length;
      if (length > 0) {
        return email.childs[length - 1];
      }
      return email;
    },
  },
}
</script>

<style>
.dd-emails > .dropdown-menu.show > a {
  min-width: 500px;
  width: 500px;
}

.dd-emails > .dropdown-menu.show > a > .message {
  width: 100%;
}
</style>
